import { AudienceType, ChannelType } from '@lasso/api-activation/activation'
import { objEntries, objFromEntries, objGroupBy } from '@lasso/shared/utils'
import { v4 as uuidv4 } from 'uuid'

import {
  AudienceAccount,
  AudienceAccountsRecord,
  AudienceEngagement,
  AudienceEngagementAction,
  AudienceExternalAccount,
  PlatformId,
  SelectedAudience,
} from './types'

type ParsedEngagement = {
  action: AudienceEngagementAction
  quantity: number
  days?: number
  weeks?: number
  months?: number
}

export const parseEngagements = (jsonString: string): AudienceEngagement[] => {
  if (!jsonString) {
    return []
  }

  let parsedEngagements: ParsedEngagement[]

  try {
    parsedEngagements = JSON.parse(jsonString)
  }
  catch {
    return []
  }

  return parsedEngagements.map((item, index) => {
    let periodValue = item.days ?? 0

    // Weeks and months aren't available in the UI
    if (item.weeks) {
      periodValue += item.weeks * 7
    }

    if (item.months) {
      periodValue += item.months * 30
    }

    return {
      id: index.toString(),
      action: item.action,
      quantity: item.quantity || 0,
      periodValue,
    }
  })
}

/* v8 ignore start - will be deleted in https://iqviadigital.atlassian.net/browse/ACT-2209 */
const channelsV1Map: Record<PlatformId, string> = {
  [ChannelType.TWITTER]: 'Twitter',
  [ChannelType.FACEBOOK_INSTAGRAM]: 'Facebook/Instagram',
  [ChannelType.LINKED_IN]: 'LinkedIn',
  [ChannelType.TIK_TOK]: 'TikTok',
  [ChannelType.SNAPCHAT]: 'Snapchat',
  [ChannelType.LIVERAMP]: 'Live Ramp',
  [ChannelType.EXTERNAL_DSP]: 'Xandr Curate',
  [ChannelType.XANDR_DMP]: 'Xandr DMP',
  [ChannelType.TRADE_DESK_DMP]: 'Trade Desk DMP',
}

const TEST_EXTERNAL_ACCOUNT_ID = '1'

/*
 * This function works with an account's test externalId.
 * It generates fake Account ID key in format like 'Twitter_mar_1',
 * consistent with key format used in v1.
 */
const getAdAccountKey = ({
  channel,
  accType,
  accId,
}: AudienceExternalAccount): string => {
  if (accId === TEST_EXTERNAL_ACCOUNT_ID) {
    const channelName = channelsV1Map[channel]
    const type = accType === 'advertiser' ? 'adv' : 'mar'
    return `${channelName}_${type}_${accId}`
  }

  return accId
}

export const parseExternalAccounts = (externalAccounts: AudienceExternalAccount[], accountId: string): {
  accounts: AudienceAccountsRecord
  platformId: PlatformId | undefined
} => {
  const accountsByChannel = objGroupBy(externalAccounts, account => account.channel)

  const accounts = objFromEntries(
    objEntries(accountsByChannel).map(([channel, accounts]) => {
      return [
        channel,
        accounts.map((account): AudienceAccount => ({
          id: getAdAccountKey(account),
          type: account.accType,
        })),
      ]
    }),
  )

  let platformId: PlatformId | undefined

  if (accountId) {
    const platform = externalAccounts.find(account => getAdAccountKey(account) === accountId)

    if (platform) {
      platformId = platform.channel
    }
  }

  return { accounts, platformId }
}
/* v8 ignore stop */

export const hasAudiencesForRetargeting = (audiences: SelectedAudience[]) => {
  return audiences.some((audience) => {
    return [AudienceType.CUSTOM, AudienceType.RETARGETING].includes(audience.audienceType)
  })
}

export const buildEngagement = (): AudienceEngagement => {
  return {
    id: uuidv4(),
    action: null,
    periodValue: null,
    quantity: null,
  }
}
